import styled from 'styled-components';
import PropTypes from 'prop-types';
import { clearFix } from 'polished';

import {
  getBackground,
  getBoxEffects,
  getBorderRadius,
  getMargin,
  getPadding,
  getBoxDimensions,
  getGrid,
} from '../../design/util';

const Box = styled.div`
  ${clearFix()}
  ${getBackground}
  ${getBoxEffects}
  ${getBorderRadius}
  ${getMargin}
  ${getPadding}
  ${getBoxDimensions}
  ${getGrid}
`;

Box.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.string,
  hasShadow: PropTypes.bool,
  isColumn: PropTypes.bool,
  isContainer: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isRounded: PropTypes.bool,
  isTransparent: PropTypes.bool,
  margin: PropTypes.number,
  padding: PropTypes.number,
  shouldFade: PropTypes.bool,
  w: PropTypes.number,
  h: PropTypes.number,
};

Box.defaultProps = {
  children: null,
  colorScheme: 'white',
  hasShadow: false,
  isColumn: false,
  isContainer: false,
  isOutlined: false,
  isRounded: false,
  isTransparent: false,
  margin: 0,
  padding: 0,
  shouldFade: false,
  w: null,
  h: null,
};

Box.displayName = 'Box';

export default Box;
