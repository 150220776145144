import { mix, transparentize } from 'polished';

export function joinPropertySet(set) {
  return set.map((i) => i && `${i}\n`).join('');
}

export function formatBackgroundString(color) {
  return `background-color: ${color};`;
}

export function formatTextColorString(color) {
  return `color: ${color};`;
}

export function formatStateStyleBlock(state, block) {
  return `
    &:${state}{
      ${block}
    }
  `;
}

/**
 * Color Helper Functions
 */

export function getDefaultBackgroundColor({
  colorScheme,
  theme,
  isControl,
  isOutlined,
  isTransparent,
}) {
  if (isOutlined || isTransparent) return formatBackgroundString('transparent');
  const color = theme.colorSchemes[colorScheme][isControl ? 'fg' : 'bg'];
  return formatBackgroundString(color);
}

export function getHoverBackgroundColor({
  colorScheme,
  theme,
  isControl,
  isOutlined,
}) {
  if (!isControl || isOutlined) return null;
  const scheme = theme.colorSchemes[colorScheme];
  const bgBlock = formatBackgroundString(mix(0.1, scheme.bg, scheme.fg));
  return formatStateStyleBlock('hover', bgBlock);
}

export function getActiveBackgroundColor({
  colorScheme,
  theme,
  isControl,
  isOutlined,
}) {
  if (!isControl) return null;
  const scheme = theme.colorSchemes[colorScheme];
  const color = isOutlined
    ? transparentize(0.9, scheme.fg)
    : mix(0.2, scheme.bg, scheme.fg);
  return formatStateStyleBlock('active', formatBackgroundString(color));
}

export function getDefaultForegroundColor({
  colorScheme,
  theme,
  isControl,
  isOutlined,
}) {
  let color = null;
  if (!isControl) {
    color = theme.colorSchemes[colorScheme].fg;
  } else {
    color = theme.colorSchemes[colorScheme][isOutlined ? 'fg' : 'bg'];
  }
  return formatTextColorString(color);
}

export function getControlFocusShadow({ theme, colorScheme, isControl }) {
  if (!isControl) return null;
  const { fg } = theme.colorSchemes[colorScheme];
  const shadowColor = transparentize(0.8, fg);
  return formatStateStyleBlock(
    'focus',
    `box-shadow: 0 0 0 3px ${shadowColor};`,
  );
}

export function getBorder({ colorScheme, isControl, isOutlined, theme }) {
  if (colorScheme in theme.colorSchemes && isOutlined) {
    const color = theme.colorSchemes[colorScheme][isControl ? 'fg' : 'bg'];
    return `border: 1px solid ${color};`;
  }
  return 'border: 0;';
}

export function getShadow({ hasShadow, theme }) {
  if (hasShadow) return `box-shadow: ${theme.shadow};`;
  return null;
}

export function getFade({ shouldFade }) {
  if (shouldFade) {
    return `
      transition: .25s background-color ease-in,
                  .25s color ease-in;
    `;
  }
  return null;
}

export function getBackground(props) {
  return joinPropertySet([
    getDefaultBackgroundColor(props),
    getHoverBackgroundColor(props),
    getActiveBackgroundColor(props),
    getFade(props),
  ]);
}

export function getForeground(props) {
  return joinPropertySet([getDefaultForegroundColor(props)]);
}

export function getBoxEffects(props) {
  return joinPropertySet([
    getBorder(props),
    getControlFocusShadow(props),
    getShadow(props),
  ]);
}

/**
 * Layout Helper Functions
 */

export function getBorderRadius({ isRounded, theme }) {
  if (isRounded) return `border-radius: ${theme.borderRadius}px;`;
  return null;
}

export function joinSpaceValueList(indeces, space) {
  return indeces.map((i) => `${space[i]}px`).join(' ');
}

export function getMargin({ m, theme }) {
  if (!m) return null;
  const paddingValue = Array.isArray(m)
    ? joinSpaceValueList(m, theme.space)
    : `${theme.space[m]}px`;
  return `margin: ${paddingValue};`;
}

export function getPadding({ p, theme }) {
  if (!p) return null;
  const paddingValue = Array.isArray(p)
    ? joinSpaceValueList(p, theme.space)
    : `${theme.space[p]}px`;
  return `padding: ${paddingValue};`;
}

export function getFontSize({ textSize, theme }) {
  return `font-size: ${theme.fontSizes[textSize]}rem;`;
}

export function getFontWeight({ boldness, theme }) {
  if (boldness < theme.fontWeights.length) {
    return `font-weight: ${theme.fontWeights[boldness]};`;
  }
  return null;
}

export function getTextTransform({ isAllCaps }) {
  if (isAllCaps) return 'text-transform: uppercase;';
  return null;
}

export function getLetterSpacing({ isAllCaps }) {
  if (isAllCaps) return 'letter-spacing: 0.15rem;';
  return null;
}

export function getTypography(props) {
  return joinPropertySet([
    getFontSize(props),
    getFontWeight(props),
    getTextTransform(props),
    getLetterSpacing(props),
  ]);
}

export function getWidth({ w, theme, isColumn }) {
  if (!w || isColumn) return null;
  const width = w > 0 && w < 1 ? `${w * 100}%` : `${theme.space[w]}px`;
  return `width: ${width};`;
}

export function getHeight({ h, theme }) {
  if (!h) return null;
  const height = h > 0 && h < 1 ? `${h * 100}%` : `${theme.space[h]}px`;
  return `height: ${height};`;
}

export function getBoxDimensions(props) {
  return joinPropertySet([getWidth(props), getHeight(props)]);
}

export function getContainer({ isContainer }) {
  if (isContainer) return 'display: flex;';
  return null;
}

export function getColumn({ w, isColumn }) {
  if (isColumn && !!w && w > 0 && w < 1) {
    return `
      float: left;
      flex-basis: ${w * 100}%;
      box-sizing: border-box;
    `;
  }
  return null;
}

export function getGrid(props) {
  return joinPropertySet([getContainer(props), getColumn(props)]);
}

export function createResponsiveBlock(targetScreen) {
  return ({ theme }) => {
    let mediaQueryString = '@media ';
    let minWidthSet = false;

    if (theme.screenOrder.indexOf(targetScreen) > 0) {
      minWidthSet = true;
      mediaQueryString += `(min-width: ${theme.screenSizes[targetScreen]}px) `;
    }

    if (
      theme.screenOrder.indexOf(targetScreen) <
      theme.screenOrder.length - 1
    ) {
      if (minWidthSet) mediaQueryString += 'and ';

      const screenOrderIndex = theme.screenOrder.indexOf(targetScreen);
      const nextScreenKey = theme.screenOrder[screenOrderIndex + 1];
      const maxWidth = theme.screenSizes[nextScreenKey] - 0.02;
      mediaQueryString += `(max-width: ${maxWidth}px) `;
    }

    return mediaQueryString;
  };
}

export default {
  getBackground,
  getForeground,
  getBoxEffects,
  getTypography,
  getBorderRadius,
  getMargin,
  getPadding,
  getBoxDimensions,
  getGrid,
  createResponsiveBlock,
  joinSpaceValueList,
};
