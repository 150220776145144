import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '../box';
import Label from '../label';
import util from '../../design/util';

const ClickableBox = styled(Box.withComponent('button'))`
  background-color: ${(props) => (props.hideBackground ? 'transparent' : null)};
  border-width: ${(props) => (props.isLink ? '0' : null)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: ${(props) => (props.isFullWidth ? 'block' : 'inline')};
  width: ${(props) => (props.isFullWidth ? '100%' : null)};
  opacity: ${(props) => (props.disabled ? '.5' : '1.0')};
  outline: none;
  position: relative;
  transition: color 0.1s ease-in, background-color 0.1s ease-in;
  vertical-align: middle;
  z-index: 1;
  white-space: nowrap;

  &::before {
    ${util.getBorderRadius}
    content: '';
    position: absolute;
    transition: box-shadow 0.1s 0.1s ease-in;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &:focus::before {
    box-shadow: ${util.getControlFocusShadow};
  }
`;

const Button = ({
  children,
  colorScheme,
  textSize,
  isLink,
  isOutlined,
  onClick,
  type,
  ...props
}) => (
  <ClickableBox
    colorScheme={colorScheme}
    isLink={isLink}
    isOutlined={isOutlined || isLink}
    isControl
    isRounded
    onClick={onClick}
    type={type}
    {...props}
  >
    <Label
      boldness={1}
      colorScheme={colorScheme}
      textSize={textSize}
      isAllCaps
      isControl
      isOutlined={isOutlined || isLink}
    >
      {children}
    </Label>
  </ClickableBox>
);

Button.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.string,
  disabled: PropTypes.bool,
  isLink: PropTypes.bool,
  isOutlined: PropTypes.bool,
  textSize: PropTypes.number,
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button']),
  ...Box.propTypes,
};

Button.defaultProps = {
  children: null,
  colorScheme: 'primary',
  disabled: false,
  isLink: false,
  isOutlined: false,
  textSize: 0,
  isFullWidth: false,
  type: 'button',
  onClick: () => {},
};

Button.displayName = 'Button';

export default Button;
