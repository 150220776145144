import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  getForeground,
  getTypography,
  getMargin,
  getPadding,
} from '../../design/util';

const Label = styled.div`
  ${getForeground}
  ${getTypography}
  ${getMargin}
  ${getPadding}
`;

Label.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.string,
  textSize: PropTypes.number,
  boldness: PropTypes.number,
  margin: PropTypes.number,
  padding: PropTypes.number,
  isAllCaps: PropTypes.bool,
  isOutlined: PropTypes.bool,
  isControl: PropTypes.bool,
};

Label.defaultProps = {
  children: null,
  colorScheme: 'white',
  textSize: 0,
  boldness: 0,
  margin: 0,
  padding: 0,
  isAllCaps: false,
  isOutlined: false,
  isControl: false,
};

Label.displayName = 'Label';

export default Label;
