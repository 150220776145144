import { transparentize } from 'polished';

export const colorSchemes = {
  primary: { bg: '#1e88e5', fg: '#fff' },
  secondary: { bg: '#bbdefb', fg: '#0d47a1' },
  accent: { bg: '#b388ff', fg: '#fff' },
  light: { bg: '#fafafa', fg: '#424242' },
  white: { bg: '#fff', fg: '#1e88e5' },
  danger: { bg: '#e91e63', fg: '#fff' },
};

export const pixels = {
  square:
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
};

export const shadow = `
  0 .15625rem .46875rem 0 ${transparentize(0.93, '#0d47a1')},
  0 .46875rem 1.875rem 0 ${transparentize(0.9, '#0d47a1')};
`;

export const space = [0, 8, 16, 32, 64, 128, 256, 512];

export const borderRadius = 4;

export const fontWeights = [300, 500, 700];

export const fontFamilyName = 'IBM Plex Sans';

export const fontSizesPx = [10, 14, 18, 20, 24, 28, 36, 72];

export const fontSizes = [0.625, 0.875, 1.125, 1.25, 1.5, 1.75, 2.25, 4.5];

export const spaceRatio = 1.618;

export const pagePadding = 3;
export const pagePaddingLargeLaptop = 5;
export const pagePaddingDesktop = 7;

export const screens = {
  MOBILE_SM: 'MOBILE_SM',
  MOBILE_LG: 'MOBILE_LG',
  TABLET: 'TABLET',
  LAPTOP_SM: 'LAPTOP_SM',
  LAPTOP_LG: 'LAPTOP_LG',
  DESKTOP: 'DESKTOP',
};

export const screenSizes = {
  [screens.MOBILE_SM]: 0,
  [screens.MOBILE_LG]: 321,
  [screens.TABLET]: 415,
  [screens.LAPTOP_SM]: 1024,
  [screens.LAPTOP_LG]: 1280,
  [screens.DESKTOP]: 2561,
};

export const screenOrder = [
  screens.MOBILE_SM,
  screens.MOBILE_LG,
  screens.TABLET,
  screens.LAPTOP_SM,
  screens.LAPTOP_LG,
  screens.DESKTOP,
];

export const overlays = {
  DEFAULT: 'DEFAULT',
  SIDEBAR_NAV: 'SIDEBAR_NAV',
  TOOLBAR_NAV: 'TOOLBAR_NAV',
};

export const overlayOrder = [
  overlays.DEFAULT,
  overlays.TOOLBAR_NAV,
  overlays.SIDEBAR_NAV,
];

export default {
  borderRadius,
  colorSchemes,
  fontSizes,
  fontWeights,
  fontFamilyName,
  pixels,
  shadow,
  space,
  spaceRatio,
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
  screens,
  screenSizes,
  screenOrder,
  overlays,
  overlayOrder,
};
