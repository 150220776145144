import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import Button from '../components/button';

import theme, {
  pagePadding,
  pagePaddingLargeLaptop,
  screens,
  space,
  colorSchemes,
  fontFamilyName,
  fontWeights,
} from '../design/theme';
import { createResponsiveBlock } from '../design/util';

const googleFontId = fontFamilyName.split(' ').join('+');
const fontWeightString = fontWeights.join(',');

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  * {
    font-family: '${fontFamilyName}', sans-serif !important;
  }

  .layout {
    @include make-container();
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  hr {
    background-color: transparent;
    border-style: solid;
    border-width: 1px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  padding: ${space[3]}px ${space[pagePadding]}px;
  display: block;
  background-color: ${colorSchemes.secondary.bg};
  word-break: break-word;

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: ${space[4]}px ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: ${space[3]}px ${space[pagePaddingLargeLaptop]}px;
  }
`;

const PageTitle = styled.h1`
  color: ${colorSchemes.secondary.fg};
`;

const ButtonContainer = styled.div`
  margin-top: ${space[3]}px;
`;

const ButtonLabel = styled.span`
  margin-left: ${space[1]}px;
`;

function goToHomepage() {
  window.location.href = '/';
}

const PrivacyPage = () => (
  <ThemeProvider theme={theme}>
    <ContentContainer>
      <Helmet
        titleTemplate="Zach Power - %s"
        defaultTitle="Zach Power - Software Engineer in San Francisco, CA"
      >
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <style>
          {`@import url('https://fonts.googleapis.com/css?family=${googleFontId}:${fontWeightString}');`}
        </style>
      </Helmet>
      <GlobalStyle />

      <small>Updated February 19, 2019</small>

      <PageTitle>Privacy Policy and User Agreement</PageTitle>

      <h2>Privacy Policy</h2>

      <h3>Terms</h3>

      <ul>
        <li>“Google” refers to the company Google, Inc.</li>
        <li>
          “This website” refers to the website and all pages accessible under
          the Internet domain name https://www.zachpwr.com
        </li>
        <li>
          “This site” refers to the website and all pages accessible under the
          Internet domain name https://www.zachpwr.com
        </li>
        <li>
          “Google Analytics” refers to a web traffic analysis service provided
          by the company Google, Inc.
        </li>
        <li>
          “Contact Me form” refers to the form on this website
          (“https://www.zachpwr.com”) which allows the user to send a message to
          the owner of this website.
        </li>
      </ul>

      <h3>Types of Information Collected by This Website</h3>

      <h4>Cookie Data and Anonymous Traffic Data</h4>

      <p>
        This website (“https://www.zachpwr.com”) collects both cookie data and
        anonymous traffic data. The cookie data and anonymous traffic data that
        is collected by this website is used by this website to analyze and
        improve the usability of the user interface of this website.
      </p>

      <h4>User-Submitted Form Data Containing Personal Information</h4>

      <p>
        When a user submits the Contact Me form, this website
        (“https://www.zachpwr.com”) collects user-provided personal information
        consisting of the user’s full name, email address, and employer. This
        personal information is only collected if the user provides his or her
        personal information and clicks the “Send” button on the “Contact Me”
        form. This personal information is collected with the consent of the
        user. This personal information is collected in order to send the user’s
        message to the owner of this website. This information is also used to
        provide the owner of this website with the information required in order
        to respond to the user via email regarding the user’s message.
      </p>

      <h3>How Information is Collected on This Site</h3>

      <h4>
        Anonymous Traffic Data and Cookie Data Collection Using Google Analytics
      </h4>

      <p>
        This website (“https://www.zachpwr.com”) collects both cookie data and
        anonymous traffic data using Google Analytics. Google Analytics is a
        service provided by Google. Google Analytics tracks and analyzes the use
        of this site (“https://www.zachpwr.com”). Information gathered by Google
        Analytics is anonymous, and is used in Google Analytics as described at
        https://policies.google.com/technologies/partner-sites
      </p>

      <p>
        Information Collected: Cookies and anonymous traffic data
        <br />
        Place of Processing: USA
        <br />
        Google Privacy Policy:
        https://policies.google.com/technologies/partner-sites
      </p>

      <h4>User-Submitted Personal Information Using Contact Me Form</h4>

      <p>
        User-submitted personal information is collected from the user when the
        user submits his or her name, email address, employer, and message to
        the Contact Me form on this website. This information is only collected
        when the user provides this information using the Contact Me form and
        hitting the “Send” button.
      </p>

      <h3>How Information is Used by This Site</h3>

      <h4>Cookie and Anonymous Traffic Data</h4>

      <p>
        Cookie and anonymous traffic data collected by this website
        (“https://www.zachpwr.com”) is used to analyze the effectiveness of the
        user interface of this website. The cookie and anonymous traffic data
        collected by this website is sent to Google Analytics. Information on
        how Google Analytics’ uses data collected from this website can be found
        at https://policies.google.com/technologies/partner-sites
      </p>

      <h4>User-Submitted Personal Information</h4>

      <p>
        When the user hits “Send” on the Contact Me form, he or she is required
        to have provided his or her full name, email address, employer, and
        message to submit the Contact Me form. If this required personal
        information is submitted by the user, it is sent to the owner of this
        website. This information is then used by the owner of this website to
        respond to the user’s message via email.
      </p>

      <h3>How the User Can Control Data</h3>

      <h4>Cookie and Anonymous Traffic Data</h4>

      <p>
        Cookie and anonymous traffic data is collected by this website
        (“https://www.zachpwr.com”) using Google Analytics. Google Analytics is
        a service provided by Google. Google allows the user to control the data
        collected that is collected through Google services including Google
        Analytics. Information regarding user control over data can be found in
        the “How you can control the information collected by Google on these
        sites and apps” section at
        https://policies.google.com/technologies/partner-sites
      </p>

      <h2>User Agreement</h2>

      <p>
        By accessing this site (“https://www.zachpwr.com”), you (the “user”) are
        agreeing to this Privacy Policy and consenting to the collection
        described in this Privacy Policy. By submitting personal information to
        the Contact Me form on this website, you are agreeing to the collection
        of the personal information that you provide, and are agreeing to
        receive communications from the owner of this website via the email
        address that you provide. By submitting personal information to the
        Contact Me form, you are also agreeing that you are above the age of
        eighteen, that your message is business-related, that the information
        that you have provided is accurate, and that your message does not
        contain advertising of products or services.
      </p>

      <ButtonContainer>
        <Button p={1} onClick={goToHomepage} colorScheme="secondary" isOutlined>
          <FontAwesomeIcon icon={faArrowLeft} />
          <ButtonLabel>Back to zachpwr.com</ButtonLabel>
        </Button>
      </ButtonContainer>
    </ContentContainer>
  </ThemeProvider>
);

export default PrivacyPage;
